import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link, graphql } from "gatsby";
import {
  newsHeader,
  newsItem,
  newsItemContent,
  newsItemDate,
  newsItemDescWrapper,
  newsItemDescription,
  newsItemHeader,
  newsItemImage,
  newsItemImages,
  newsItemInfo,
  newsItemSubTitle,
  newsItemTitle,
  newsProjectLink,
  newsWrapper,
} from "./styles/index.module.scss";

import React from "react";
import ReactMarkdown from "react-markdown";
import { format } from "date-fns";

const Index = ({ data }) => {
  const news =
    data.allStrapiNewsItems?.nodes?.map((newsEntry) => {
      newsEntry.date = new Date(newsEntry.date);
      return newsEntry;
    }) ?? [];
  return (
    <div className={`${newsWrapper} fade-in`}>
      <h1 className={`${newsHeader} d-none d-lg-block`}>
        büro ganster <br /> architektur
      </h1>
      <h2 className={`${newsHeader} d-block d-lg-none`}>aktuell</h2>
      <div>
        {news.map((newsEntry, newsIndex) => (
          <div
            key={`${newsEntry.title}-${newsEntry.date}`}
            className={newsItem}
          >
            <div className={newsItemHeader}>
              <p className={newsItemDate}>{format(newsEntry.date, "MM/yy")}</p>
              <h3 className={newsItemTitle}>
                {newsEntry.title}
                {newsEntry.location && ` / ${newsEntry.location}`}
              </h3>
            </div>
            <div className={newsItemContent}>
              <p className={newsItemInfo}>{newsEntry.info}</p>
              <div className={newsItemImages}>
                {newsEntry.images?.map((image, index) => (
                  <GatsbyImage
                    width="280px"
                    loading={newsIndex > 1 ? "lazy" : "eager"}
                    className={newsItemImage}
                    key={`${newsEntry.title}-image-${index}`}
                    image={getImage(image.localFile)}
                    alt={newsEntry.title}
                  ></GatsbyImage>
                ))}
              </div>
            </div>
            <div className={newsItemDescWrapper}>
              {newsEntry.project && (
                <Link
                  to={`/projekte/${newsEntry.project.slug}/`}
                  className={newsProjectLink}
                >
                  zum projekt
                </Link>
              )}
              <p className={newsItemSubTitle}>
                {newsEntry.subtitle ? newsEntry.subtitle : null}
              </p>
              <ReactMarkdown className={newsItemDescription}>
                {newsEntry.description}
              </ReactMarkdown>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Index;

export const query = graphql`
  query {
    allStrapiNewsItems(sort: { order: ASC, fields: position }) {
      nodes {
        images {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: NONE, quality: 10)
            }
          }
        }
        description
        date
        info
        location
        title
        subtitle
        year_only
        project {
          slug
        }
      }
    }
  }
`;
