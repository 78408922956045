// extracted by mini-css-extract-plugin
export var dNone = "index-module--d-none--q5CKH";
export var dBlock = "index-module--d-block--yiB6j";
export var dInline = "index-module--d-inline--synSE";
export var dFlex = "index-module--d-flex--VkWh-";
export var dMdNone = "index-module--d-md-none--ZVvDG";
export var dMdBlock = "index-module--d-md-block--DRkbX";
export var dMdFlex = "index-module--d-md-flex--nvKRj";
export var dLgFlex = "index-module--d-lg-flex--BuO04";
export var dLgNone = "index-module--d-lg-none--G7Frc";
export var dLgBlock = "index-module--d-lg-block--AAGYR";
export var dLgInline = "index-module--d-lg-inline--hSh5l";
export var newsWrapper = "index-module--newsWrapper--FrvTJ";
export var newsHeader = "index-module--newsHeader--9m3sj";
export var newsItem = "index-module--newsItem--URA6e";
export var newsItemTitle = "index-module--newsItemTitle--GEyvj";
export var newsItemInfo = "index-module--newsItemInfo--B4FJs";
export var newsItemDate = "index-module--newsItemDate--+Nyon";
export var newsItemImages = "index-module--newsItemImages--+dMma";
export var newsItemImage = "index-module--newsItemImage--FB1BR";
export var newsItemDescWrapper = "index-module--newsItemDescWrapper--5STN3";
export var newsItemSubTitle = "index-module--newsItemSubTitle--IQyOq";
export var newsItemDescription = "index-module--newsItemDescription--M9AJJ";
export var newsProjectLink = "index-module--newsProjectLink--37QCU";
export var newsItemHeader = "index-module--newsItemHeader--l90du";
export var newsItemContent = "index-module--newsItemContent--kWFFZ";